<template>

  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5">
                <div class="brand-logo text-center">
                  <img src="../../../assets/images/Logo.png">
                </div>
                <h4>Hello! Selamat Datang</h4>
                <h6 class="font-weight-light">Sign in untuk melanjutkan.</h6>
                <form class="pt-3">
                  <div class="form-group">
                    <input type="email" v-model="form.email" class="form-control form-control-lg" placeholder="Email">
                  </div>
                  <div class="form-group">
                    <input type="password" v-model="form.password" class="form-control form-control-lg" placeholder="Password">                  
                  </div>
                  <div class="mt-3">
                    <template v-if="alert.status != 0">
                      <h4 class="pt-3 text-center" style="color:#e91e63;">{{ alert.message }}</h4>
                    </template>                    
                    <b-button ref="containerButton" class="w-100 btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;" variant="info" @click="onSubmit"><i class="fa fa-sign-in opacity-50"></i> Masuk</b-button>                    
                    <!-- <router-link class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" to="/">SIGN IN</router-link> -->
                  </div>
                  <div class="my-2 d-flex justify-content-between align-items-center">
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input">
                        Keep me signed in
                        <i class="input-helper"></i>
                      </label>
                    </div>
                    <a href="javascript:void(0);" class="auth-link text-black">Lupa Password?</a>
                  </div>
                  <!-- <div class="mb-2">
                    <button type="button" class="btn btn-block btn-facebook auth-form-btn">
                      <i class="mdi mdi-facebook mr-2"></i>Connect using facebook
                    </button>
                  </div> -->
                  <!-- <div class="text-center mt-4 font-weight-light">
                    Don't have an account? <router-link to="/auth-pages/register" class="text-primary">Create</router-link>
                  </div> -->
                </form>
              </div>
            </div>
          </div>
      </div>
      <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>
import axios from 'axios'
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'login',
  data(){
    return{
      passwordHidden: false,
      errorMassage:0,
      form:{
        email:'',
        password:''
      },
      alert:{
        status: 0,
        message:'',
      },
      data: []
    }
  },
  methods:{
    // hidePassword : function name() {
    //   this.passwordHidden = false;         
    // },
    // showPassword : function name() {
    //   this.passwordHidden = true;         
    // },      
    onSubmit(evt) {
      evt.preventDefault()
      // var loader = this.$loading.show({
      //   container: this.$refs.containerButton,canCancel: true,onCancel: this.onCancel,
      // });
      let checkField = packageGlobals.checkField(
        [
          {value : this.form.email, message : 'Email wajib diisi',key:'email'},
          {value : this.form.password, message : 'Password wajib diisi',key:'password'}                  
        ]
      )
      this.alert.status = checkField.flag
      this.alert.message = checkField.message

      if (this.form.email != '' && this.form.password != '') {
        // const config = {
        //   headers: {
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Allow-Methods": "POST",
        //     "Content-Type": "application/x-www-form-urlencoded",
        //     "Access-Control-Allow-Headers":
        //     "Content-type,Accept,X-Access-Token,X-Key",
        //   },
        //   crossDomain: true,
        //   crossorigin: true,
        // };        
        axios.post(`${packageStore.mainApi}/api/web/login`,this.form)
        .then(response => {
          this.responseAxios(response)          
          // loader.hide()          
        })
      }
    },
    responseAxios: function (response) {
      if (response.data.status == 200) {
        this.data = response.data.result
        localStorage.setItem('uuid',this.data.user.uuid);
        localStorage.setItem('group_id',this.data.user.group_id);
        localStorage.setItem('fullname',this.data.user.fullname);
        localStorage.setItem('email',this.data.user.email);                    
        localStorage.setItem('token',this.data.token.token);
        localStorage.setItem('refreshToken',this.data.token.refreshToken);
        localStorage.setItem('typeToken',this.data.token.type);
        setTimeout(() => {
          location.reload()              
        }, 1800);          
        this.$router.push('/dashboard')
      } else {
        console.log(response.data.status);
        switch (response.data.status) {
          case 401:
            this.$swal.fire({
              title: "Ups, ada Kesalahan",
              text: response.data.result[0].message,
              icon: "error",
              confirmButtonText: "Ok",
            })
            break
          default:
            break
        }
      }
    },				    
  },
  mounted() {
    localStorage.clear()
    this.alert.status = 0
  }  
}
</script>
